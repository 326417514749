.dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4DB0F4 !important;
}

.dropdown .css-1aalyuy-MuiStack-root>:not(style)~:not(style) {
    margin-top: 0px !important;
}

.dropdown .css-1aalyuy-MuiStack-root>:not(style)~:not(style) .password-label {
    margin-top: 25px !important;
}
