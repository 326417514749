.bg-home {
    width: 100%;
    height: 60%;
}

.login {
    position: absolute;
    top: 150px;
}

.image1 {
    width: 60%;
    height: 70%;
}

.grey-background {
    background-color: #F5F5F5 !important;
}

.description {
    width: 40%;
    font-weight: 500;
    display: inline-block;
    text-align: justify;
}

.description-2 {
    width: 60%;
    font-weight: 500;
    display: inline-block;
    text-align: justify;
}

.register-container {
    text-align: center;
    display: flex;
    justify-content: flex-start;
}

.register-container1 {
    text-align: center;
    display: flex;
    justify-content: center;
}


.register-text {
    width: 50%;
    text-align: justify;
}

.text-font-weight {
    font-weight: 600;
    color: #ffffff;
}

.footer-background {
    background-color: #bcbbbb!important;
}