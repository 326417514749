.grey-background {
    background-color: #F5F5F5 !important;
}

.register-container2 {
    text-align: center;
    display: flex;
    justify-content: center;
}

.register-text {
    width: 50%;
    text-align: justify;
}