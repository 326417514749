.changed {
    background-color: lightgreen;
}

.new-fields {
    color: green;
}

.old-fields {
    color: red;
}

.old-fields-headers {
    margin-bottom: -20px;
}