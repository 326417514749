.register {
    max-width: 450px;
    margin: auto;
    border: none !important;
}

.register-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #4DB0F4 !important;
}

.register .css-1aalyuy-MuiStack-root>:not(style)~:not(style) {
    margin-top: 2px !important;
}

.register-form .css-1aalyuy-MuiStack-root>:not(style)~:not(style) .password-label {
    margin-top: 25px !important;
} 

.label-checkbox{
    font-weight: 500 !important;
    font-size: 16px !important;
    font-style: sans-serif;
    margin: 20px 0px 20px 0px;
}